<script lang="ts">
	import { siteConfig } from "$lib/config";
	import { Menu } from "lucide-svelte";
	import { getDrawerStore, type DrawerSettings } from "@skeletonlabs/skeleton";
	import { page } from "$app/stores";
	import Link from "./Link.svelte";
	const drawerStore = getDrawerStore();

	const navDrawerSettings: DrawerSettings = {
		id: "nav-drawer",
		padding: "p-4",
		rounded: "rounded-md"
	};

	let classesActive = $derived((href: string) => {
		return $page.url.pathname === href ? "border-b-[1px] border-b-white" : "";
	});
</script>

<header class="md:w-full h-full mx-auto mt-9 mb-16 grid grid-cols-2 grid-flow-col">
	<div id="site-iden" class="flex flex-col gap-3 italic">
		<h1 class="h2 text-left">
			<Link href="/" enableUnderline={false} class="!font-bold">Rookie's Blog</Link>
		</h1>
		<p class="text-gray-500">{siteConfig.tagline}</p>
	</div>

	<nav class="self-end text-right hidden md:block">
		<span class="links-list flex flex-row justify-end mb-4">
			{#each siteConfig.socials as socialLink}
				<a href={socialLink.link} class="ml-5">
					<socialLink.icon color="#FFFFFF" size="20" />
				</a>
			{/each}
		</span>
		<span class="routes-list">
			{#each siteConfig.routes as route}
				<a
					href={route.path}
					class="ml-5 {classesActive(
						route.path
					)} hover:border-dashed hover:border-b-[1px] hover:border-b-tertiary-600">{route.name}</a
				>
			{/each}
		</span>
	</nav>

	<div class="self-center text-right">
		<button
			class="md:hidden btn btn-sm variant-soft-primary"
			onclick={() => drawerStore.open(navDrawerSettings)}
		>
			<Menu />
		</button>
	</div>
</header>

<hr class="text-center mx-auto w-9/12 md:w-11/12 my-10 md:my-16 lg:my-12" />
